<template>
  <AdminReportingForm
    title="Site Markers"
    description="Site Markers are generated at the park level for the arrival date specified and include a page of information relating to each reservation scheduled for that arrival date.  This report can be run for any past, current, or future date."
    file="SiteMarker$"
    maxLength="14"
    :reportStatus="reportStatus"
    :submitting="submitting"
    :pdfOnly="true"
    :showSortBy="true"
    @getReport="getReport"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "SiteMarkerReport",
  title: "Admin - Site Markers Report",
  data() {
    return {
      reportStatus: "",
      submitting: false
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();

      const service = new AdminReportingService(this.tenantId);
      service
        .getSiteMarkerReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
